<template>
    <div class="login-page">
        <div class="login-container">
            <div class="login-title">社区团购平台</div>
            <div class="login-field">
                <img class="login-field-icon" src="~@/assets/image/3.png" alt="" />
                <input v-model="username" class="login-field-value" placeholder="请输入用户名" autocomplete="new-password"
                    type="text" />
            </div>
            <div class="login-field">
                <img class="login-field-icon" src="~@/assets/image/4.png" alt="" />
                <input v-model="password" class="login-field-value" placeholder="请输入密码" autocomplete="new-password"
                    type="password" />
            </div>
            <div class="login-checkbox" @click="save = !save">
                <img v-show="!save" class="login-checkbox-icon" src="~@/assets/image/5.png" alt="" />
                <img v-show="save" class="login-checkbox-icon" src="~@/assets/image/6.png" alt="" />
                <div class="login-checkbox-text">记住密码</div>
            </div>
            <div class="login-button" @click="onLogin">登录</div>
        </div>
    </div>
</template>

<script>
import { post } from "@/assets/js/http.js";

export default {
    name: "view-login",
    data() {
        return {
            save: false,
            username: "",
            password: ""
        };
    },
    mounted() {
        this.save = localStorage.getItem("save") === "true";
        if (this.save) {
            this.username = localStorage.getItem("username");
            this.password = localStorage.getItem("password");
        } else {
            this.username = "";
            this.password = "";
        }
    },
    methods: {
        async onLogin() {
            if (this.username && this.password) {
                try {
                    localStorage.setItem("save", this.save);
                    if (this.save) {
                        localStorage.setItem("username", this.username);
                        localStorage.setItem("password", this.password);
                    } else {
                        localStorage.removeItem("username");
                        localStorage.removeItem("password");
                    }
                        const data = await post({
                        url: "/admin/index/login",
                        loading: true,
                        successTip: true,
                        data: {
                            username: this.username,
                            password: this.password
                        }
                    });
                    
                    this.$store.commit("login", data.userinfo);
                    this.$router.replace("/");
                }catch(err){
                }finally {

                }
            } else {
                this.$message.error("用户名和密码不能为空");
            }
        }
    }
};
</script>